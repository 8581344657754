import React from 'react';

const FakeDoor = () => {
  return (
    <section className="App bg-white h-screen">
      <div className=" flex flex-col md:flex-row items-center">
        <div className="mx-20 md:w-1/2 left">
        <img
            className="w-2/3 my-8"
            src="PartnerupLogo.svg"
            alt="Introduction"
          />
          {/* Title */}
          <h1 className="text-4xl my-8">Welcome to Partners Personnel Client 
          Portal Preview</h1>

          {/* Paragraph */}
          <p className="text-gray-600 text-xl">
          At Partners Personnel, we believe in innovation,
           and we're excited to invite you to tell us what’s most important to you!
          </p>
          <h2 className="text-lg font-semibold mt-8">What can you expect from our Partnerup client portal?</h2>
          <ul className="text-gray-600 text-lg list-disc inline-block text-left mt-4 ml-8">
            <li>Dashboard Delight: Get a bird's-eye view of your workforce data in one place.</li>
            <li>Seamless Job Orders: Effortlessly submit and track job orders.</li>
            <li>Insightful Reports: Dive into data-driven insights for better decision-making.</li>
            <li>Associate Hub: Manage your current and past associates with ease.</li>
            <li>Effortless Invoicing: View and pay invoices without the fuss.</li>

          </ul>
          <p className="text-black font-medium mt-16 text-2xl text-center">
            Cast your vote on priority features here.
          </p>
          <div class="flex flex-col justify-center items-center">
            <a href="https://form.jotform.com/232536682063154"
            className="w-2/3 bg-gradient-to-r from-blue-700 via-blue-500 to-blue-400 mt-4 text-center text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            type="submit"
            >
            Vote now!
            </a>
            <p className="mt-4">Looking for a job?</p>
            <p className="mt-0">¿Buscando Trabajo?</p>
            <a href="https://jobs.partnerspersonnel.com/job-search?limit=25" className="mt-2 text-blue-600 underline">Click here - Haga click aqui</a>
            <p className="text-gray-400 mt-8 mb-4 text-xs">
              © partnerup 2023. All right reserved
            </p>
          </div>
        </div>

        <div className="md:w-1/2 md:h-full md:mt-0 w-0 h-0">
          {/* Image */}
          <img
            className="w-full h-screen rounded-md shadow-md"
            src="PartnersPersonnelStaffing.png"
            alt="Introduction"
          />
        </div>
      </div>
    </section>
  );
};

export default FakeDoor;
